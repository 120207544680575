import React from 'react';
import { createGlobalStyle } from 'styled-components'

// Utils
import { Colors } from './utils/constants'
import mediaQuery from './utils/mediaQuery'

// Components
import NavBar from './NavBar'
import Footer from './Footer'

// Fonts
//import '../common/fonts/Cafe-Francoise_F.otf'
//import '../common/fonts/Cafe_Francoise_F.ttf'
import '../common/fonts/Cafe_Francoise_F.woff'
import '../common/fonts/Muli-ExtraBold.ttf'
import '../common/fonts/Muli-SemiBold.ttf'


const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: "Cafe Francoise";
        //src: url("fonts/Cafe-Francoise_F.otf") format("otf");
        //src: url("fonts/Cafe_Francoise_F.ttf") format("truetype");
        src: url("fonts/Cafe_Francoise_F.woff") format("woff");
    }

    @font-face {
        font-family: "Muli SemiBold";
        src: url("fonts/Muli-SemiBold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Muli ExtraBold";
        src: url("fonts/Muli-ExtraBold.ttf") format("truetype");
    }
    
    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        background-color: ${Colors.background};
        color: ${Colors.TextBody}
    }

    
    h1 {
        font-family: 'Cafe Francoise',sans-serif;
        font-size: 30px;
        color: #ffffff;

        ${mediaQuery.tablet`
            font-size: 30px;
        `}

        ${mediaQuery.desktop`
            font-size: 44px;
            line-height: 50px;
        `}

        span {
            text-decoration: underline;
        }
    }

    h2 {
        font-family: 'Cafe Francoise', sans-serif;
        color: #ffffff;
        font-size: 30px;

        ${mediaQuery.desktop`
            font-size: 36px;
        `}

        span {
            text-decoration: underline;
        }

        ${mediaQuery.phone`
            font-family: 'Cafe Francoise', sans-serif;
        `}
    }

    h3 {
        font-family: 'Muli ExtraBold', sans-serif;
        
        
        ${mediaQuery.tablet`
            font-size: 20px;
        `}
        
    }

    h4 {
        font-family: 'Muli SemiBold', sans-serif;
        font-size: 14px;

        ${mediaQuery.desktop`
            font-size: 15px;
        `}
    }

    ul, ol {
        font-size: 14px;
        font-family: 'Muli SemiBold', sans-serif;

        li {
            margin-bottom: 10px;
        }
    }

    p {
        font-family: 'Muli SemiBold', sans-serif;
        color: ${Colors.TextBody};
        font-size: 14px;
        line-height: 20px;


        ${mediaQuery.tablet`
            font-size: 18px;
            line-height: 1.5em;
        `}
    }

    a {
        color: ${Colors.TextBody};
        font-family: 'Muli SemiBold', sans-serif;
        font-size: 14px;
        text-decoration: none;
    }
`

export default function Layout(props) {
    return (
        <React.Fragment>
            <GlobalStyle />
            <NavBar />

            {props.children}

            <Footer />
        </React.Fragment>
    );
}