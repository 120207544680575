import React from 'react'
import styled from 'styled-components'
import { NavHashLink } from 'react-router-hash-link'
import { withRouter } from 'react-router-dom'
import mediaQuery from '../common/utils/mediaQuery'

// Utils
import { Colors } from './utils/constants'


const Container = styled.footer`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    box-sizing: border-box;
    background-color: ${Colors.Secondary};

    ${mediaQuery.tablet`
        flex-flow: row;
    `}

    a {
        margin: 5px;
        transition: color 0.3s ease;
        :hover {
            color: #FFFFFF
        }
    
        ${mediaQuery.tablet`
            margin: 10px;
        `}
    }

`
const NavLink = styled(NavHashLink)` 

`

const NavButton = styled(NavHashLink)`
    
`

export default function Footer() {
    return (
        <Container>
            <div className="routeLinks">
                <NavLink to='/#home' className="link">Home</NavLink>
                <NavLink to='/#products' className="link">Products</NavLink>
                <NavLink to='/about' className="link">About</NavLink>
                {/* <NavLink to='/northern' className="link">Northern District</NavLink> */}
                <NavButton to='/#contact-us'> Contact Us </NavButton>
            </div>
            <a href="/#home" className="brandLabel">&copy;&nbsp; Diversity Blends 2022</a>
        </Container>
    )
}
