import React from 'react';
import styled, { keyframes } from 'styled-components'
import { Colors } from '../utils/constants';

//images
import loader from '../../images/loaderBlends.gif'
import Logo from '../../images/logo.svg'


const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.background};

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 4;
    
    .loader {
        animation: pulser 2s linear infinite;
    }

    img {
        height: auto;
        width: 250px;
    }

    @keyframes pulser {
        0% { opacity: 1; }
        50% { opacity: 0.3; }
        100% { opacity: 1; }
    } 
`

export default function Loader(props) {
    return (
        <Container fullscreen={props.fullscreen}>

            <img src={Logo} alt="loader" className="loader" />

        </Container>
    );
}