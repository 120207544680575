import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import mediaQuery from './utils/mediaQuery'

//styles
import { Colors } from '../common/utils/constants'

//images
import Menu from '../images/menu.svg'
import Close from '../images/menu_close.svg'

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    background: transparent;
    width: 100%;
    padding: 30px 30px;
    position: fixed;
    z-index: 8;
    
    &.active{
        background: ${Colors.background};
        transition:  0.5s ease 0s;
    }

    ${props => props.menuOpen && `
            .menu {
                display: none;
            }
        `}
    

    .menu {
        justify-content: flex-end;
        height: 20px;
        cursor: pointer;
    }

    .navigation {
        height: 0;
        display: none;
        align-items: center;
        flex-direction: column;
        z-index: 1;

        .close {
            display: none;
        }

        ${props => props.menuOpen && `
             height: 100vh;
             width: 100vw;
             display: flex;
             justify-content: center;

             background: ${Colors.background};
             position: fixed;
             top:0;
             right:0;
             z-index: 2;

             .link {
                margin-bottom: 40px;
                position: relative;
                
                :hover {
                    color: ${Colors.Primary};
                    transition: all 0.3s ease;
                }

                &.active::after {
                    position: absolute;
                    left: 0px;
                    top: 35px;
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    content: '';
                    transition: 0.3s;
                    background: ${Colors.Primary};
                }
             }
             
            .close {
                position: absolute;
                bottom: 100px;
                cursor: pointer;
                display: inline;
             }
        `}
    }
    
    ${mediaQuery.desktop`
        padding: 20px 40px;

        .menu {
            display: none;
            position: fixed;
            height: 10%;
        }

        .navigation {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            height: 100%;

            .link {
                padding: 10px;
                margin-right:10px;
                position: relative;
                transition: color 0.3s ease;

                :hover {
                    color: ${Colors.Primary};
                }

                &.active::after {
                    position: absolute;
                    left: 0px;
                    top: 35px;
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    content: '';
                    background: ${Colors.Primary};
                }
            }

            .close {
                display: none;
            }
        }
    `}
`

const NavLink = styled(NavHashLink)` 
    
`

const NavButton = styled(NavHashLink)`
    background: ${Colors.Secondary};
    transition: background 0.3s ease;
    color: ${Colors.TextBody};
    padding: 10px 30px;
    font-family: 'Muli ExtraBold';

    :hover {
        background: ${Colors.Primary};
    }
`

function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuScreen = 992;
    const [navbarColor, setNavbarActive] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth > menuScreen) {
                setMenuOpen(false);
            }
        })
        return () => {
            window.removeEventListener('resize', null)
        }
    }, [])

    const toggleMenu = () => {
        if (window.innerWidth < menuScreen) {
            setMenuOpen(!menuOpen)
        }
    }

    const changeBackground = () => {
        if (window.scrollY >= 55) {
            setNavbarActive(true);
        } else {
            setNavbarActive(false);
        }
    }
    window.addEventListener('scroll', changeBackground);


    return (
        <Container menuOpen={menuOpen} className={navbarColor ? 'active' : ''}>

            <img className="menu" src={menuOpen ? Close : Menu} onClick={toggleMenu} alt="menu" />

            <div className="navigation">
                <NavLink to='/#home' className="link" onClick={toggleMenu}>Home</NavLink>
                <NavLink to='/#products' className="link" onClick={toggleMenu}>Products</NavLink>
                <NavLink to='/about' className="link" onClick={toggleMenu}>About</NavLink>
                {/* <NavLink to='/northern' className="link" onClick={toggleMenu}>Northern District</NavLink> */}
                <NavButton to='/#contact-us' onClick={toggleMenu}> Contact Us </NavButton>
                <img className="close" src={Close} alt="close" onClick={toggleMenu} />
            </div>

        </Container>
    )
}

export default withRouter(NavBar)
