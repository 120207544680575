import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable'

// Components
import Loader from './common/components/Loader'
import Layout from './common/Layout'


const AsyncHome = Loadable({
  loader: () => import("./static/landing"),
  loading: () => <Loader fullscreen />
});

const AsyncAbout = Loadable({
  loader: () => import("./static/landing/sections/About"),
  loading: () => <Loader fullscreen />
});

const AsyncNorthern = Loadable({
  loader: () => import("./static/landing/sections/Northern"),
  loading: () => <Loader fullscreen />
});


// 404 Not found
const AsyncNoMatch = Loadable({
  loader: () => import("./common/NoMatch.js"),
  loading: () => <Loader fullscreen />
});

function App() {
  return (
    <Router>
      <Route render={({ location }) => (
        <Layout>

          <Switch location={location}>
            {/* STATIC */}
            <Route path="/" exact component={AsyncHome} key="index" />
            <Route path="/about" exact component={AsyncAbout} key="about" />
            <Route path="/northern" exact component={AsyncNorthern} key="northern" />
            <Route component={AsyncNoMatch} key="not_found" />
          </Switch>
        </Layout>
      )} />
    </Router>
  );
}

export default App;
